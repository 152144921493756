<template>
  <div class="">
    <ControlNavbar/>
    <div class="container" v-if="parkmeterObject">
      <div class="banner" v-if="parkmeterObject.banner">
        <Banner :banner="parkmeterObject.banner"/>
      </div>
      <a-form
          :form="form"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 12 }"
          @submit="handleSubmit"
      >
        <a-form-item :label="locale.code">
          <a-select
              v-decorator="[
              'plateCode',
              {
                initialValue: parkmeterObject.data.defaultCarPlateCode,
                rules: [
                  { required: true, messasge: locale.emptyFieldsWarning },
                ],
              },
            ]"
          >
            <a-select-option
                v-for="code in parkmeterObject.data.carPlateCode"
                :key="code"
                :value="code"
            >
              {{ code }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item :label="parkmeterObject.data.hint">
          <a-input
              v-decorator="[
              'plateNumber',
              {
                rules: [
                  {
                    required: true,
                    message: locale.emptyFieldsWarning,
                  },
                ],
              },
            ]"
          />
        </a-form-item>

        <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
          <a-button type="danger" html-type="submit">
            {{ parkmeterObject.data.retrieve }}
          </a-button>
        </a-form-item>
      </a-form>
    </div>
    <a-modal
        v-if="parkmeterModalObject"
        v-model="visible"
        :title="parkmeterModalObject.title"
        :footer="null"
    >
      <div
          v-if="
          parkmeterModalObject.data && parkmeterModalObject.data.length != 0
        "
      >
        <div
            v-for="(fine, index) in parkmeterModalObject.data"
            :key="index"
            class="fine"
        >
          <p>{{ locale.district }} {{ fine.description }}</p>
          <p>{{ locale.date }} {{ fine.issueDate }}</p>
          <p>
            {{ locale.invoice_balance }} {{ fine.shortCode }} {{ fine.amount }}
          </p>
          <p>
            {{ locale.invoice_fees }} {{ fine.shortCode }}
            {{ fine.additionalFees }}
          </p>
          <p>
            {{ locale.amount_to_be_paid }} {{ fine.shortCode }}
            {{ fine.amount + fine.additionalFees }}
          </p>
          <p>{{ locale.price }} {{ fine.deductibleAmountText }}</p>
          <div class="align-right">
            <button
                class="whish-button"
                @click="
                payfine(fine.currentPrice, fine.topupVoucherId, fine.prices)
              "
            >
              {{ parkmeterObject.data.pay }}
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <p>{{ locale.no_tickets }}</p>
      </div>
    </a-modal>
    <a-modal
        v-model="contactvisible"
        :title="parkmeterObject.data.toggle"
        @ok="handleOkContact"
        :okText="parkmeterObject.data.pay"
        :cancelText="locale.cancel"
    >
      <a-switch
          v-if="parkmeterObject.data.toggleOptional"
          default-checked
          @change="smsChange"
      />

      <a-input
          v-if="sms"
          v-model="contact"
          :placeholder="parkmeterObject.data.inputHint"
          v-decorator="[
          {
            rules: [
              {
                required: true,
                message: `${locale.emptyFieldsWarning}`,
              },
            ],
          },
        ]"
      />
    </a-modal>
    <a-modal
        v-model="paymentOptionsVisible"
        :title="locale.paymentoptions"
        @ok="paymentOptionsVisible = !paymentOptionsVisible"
        :footer="null"
        v-if="apiCost"
    >
      <div v-if="showPaymentOptions">
        <div
            class="
            d-flex
            justify-content-between
            card_custom
            pointer
            align-items-center
          "
            v-for="option in $session.get('paymentOptions')"
            :key="option.id"
            @click="selectPaymentOption(option)"
        >
          <p>{{ option.label }}</p>
          <p>
            {{ apiCost[`${option.id}`] }}
          </p>
        </div>
      </div>

      <PaymentCC
          v-if="paymentOption == 'creditcard'"
          @options="
          ($event) => {
            this.showPaymentOptions = $event;
          }
        "
          :apiBody="apiBody"
          @handleOldFine="handleOldFine"
      />
    </a-modal>
    <loading :loading="loader"/>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
// @ is an alias to /src
import ControlNavbar from "@/components/ControlNavbar";
import Banner from "@/components/Banner";
import PaymentCC from "@/components/PaymentCC";
import Loading from "./Loading.vue";
import {LocaleArabic, LocaleEnglish} from "../scripts/Locale";

export default {
  name: "Parkmeter",
  components: {ControlNavbar, Banner, PaymentCC, Loading},
  data() {
    return {
      locale: null,
      loader: false,
      sms: true,
      visible: false,
      contactvisible: false,
      parkmeterObject: this.$route.params.object,
      formLayout: "horizontal",
      form: this.$form.createForm(this, {name: "coordinated"}),
      form1: this.$form.createForm(this, {name: "coordinated1"}),
      parkmeterModalObject: null,
      contact: null,
      fineObject: {}, //used to fill the data in it to send it to the backend in the end api

      // cc cost object to be able to show prices for cc payment (look where it gets its value and you'll understand)
      apiCost: null,
      // credit card payment
      paymentOptionsVisible: false,
      paymentOption: null,
      apiBody: null,
      showPaymentOptions: true,
    };
  },
  methods: {
    checkLocal() {
      if (this.$session.get("lang") && this.$session.get("lang") == "ar") {
        this.locale = LocaleArabic;
      } else {
        this.locale = LocaleEnglish;
      }
    },
    setLoader(show) {
      this.loader = show;
    },
    smsChange(checked) {
      this.sms = checked;
    },
    toggleModal() {
      this.visible = !this.visible;
    },
    handleOk(e) {
      console.log(e);
      this.visible = false;
    },
    toggleContactModal() {
      this.contactvisible = !this.contactvisible;
    },
    isEmpty(obj) {
      for (var o in obj) {
        if (o) {
          return false;
        }
      }
      return true;
    },
    //  cc payment
    checkPaymentMethod() {
      // the whole cc payment is here just check payment solution and the modal will do the rest

      // always check prices in voucher object and server.paymentOptions
      // if both are not null
      // if topleft menu (default_payment exists) && usertogglevalue is true do api aade
      // else

      // get intersect between prices and server.paymentoptions (paymentoptions.id == voucher.prices['id']) id is the key of the json
      // if empty do normal api
      //WHISHPAYMENT AND ANY OTHER else show payment options (type, show: label) prices: show price marching with paymentoptions when chosen send optionId with the body as the id of the paymentoptions selected
      // CREDIT CARD else show payment options (type, show: label) prices: show price marching with paymentoptions when chosen send optionId with the body as the id of the paymentoptions selected

      // if both or one of them null continue aade
      this.paymentOption = null;
      if (this.$session.get("paymentOptions") && !this.isEmpty(this.apiCost)) {
        if (
            this.$session.get("userToggleValue") == true &&
            this.$session.get("default_payment") == true
        ) {
          console.log("do normal api");
          // do normal api
          return true;
        } else {
          console.log("methods");
          // check payments option
          this.paymentOptionsVisible = true;
          this.showPaymentOptions = true;
          return false;
        }
      } else {
        console.log("normal api");
        // do normal api
        return true;
      }
    },
    selectPaymentOption(payOption) {
      // when clicked on the price fields of payment options if credit card open credit card commponent and give it the values that it needs to send to the api
      if (payOption.type == "creditcard") {
        let values = this.fineObject;
        values.type = this.parkmeterObject.type;
        values.fetchPrice = true;
        values.denominationId = this.parkmeterObject.id;
        // values.numberOfItems = values.Quantity;
        // delete values.Quantity;
        // // this.checkPaymentMethod();
        // if (this.directRechargeField) {
        //   values.recipientMsisdn = this.directRechargeField;
        // }
        values.optionId = payOption.id;
        this.apiBody = values;
        this.apiBody.type = this.parkmeterObject.type;
        this.paymentOption = payOption.type;
      } else {
        this.paymentOptionsVisible = false;
        this.finishFineByWhish(payOption.id);
      }
    },
    handleOldFine(values) {
      this.paymentOptionsVisible = false;
      this.setLoader(true);
      this.getAxios()
          .post(`${this.$session.get("baseURI")}sale/bill/payment`, values)
          .then((result) => {
            this.setLoader(false);
            if (this.checkApiRes(result, values, "sale/bill/payment", "post")) {
              // ok
              this.success(result.data.dialog.title, result.data.dialog.message);
              console.log("full bill payment: ", result);
            }
            this.contact = null;
            this.contactvisible = false;
          });
    },
    // end cc payment
    finishFineByWhish(optionId) {
      if (this.contact || !this.sms) {
        this.fineObject.contact = this.contact;
        this.fineObject.optionId = optionId;
        this.setLoader(true);

        this.getAxios()
            .post(`${this.$session.get("baseURI")}sale/bill/payment`, this.fineObject)
            .then((result) => {
              this.setLoader(false);

              if (this.checkApiRes(result, this.fineObject, "sale/bill/payment", "post")) {
                // ok
                this.success(
                    result.data.dialog.title,
                    result.data.dialog.message
                );
                console.log("full bill payment: ", result);
              }
              this.contact = null;
              this.contactvisible = false;
              this.toggleModal();
            });
      }
    },
    handleOkContact(e) {
      console.log(e);
      if (this.contact || !this.sms) {
        this.fineObject.contact = this.contact;
        if (this.checkPaymentMethod()) {
          this.setLoader(true);
          this.getAxios()
              .post(
                  `${this.$session.get("baseURI")}sale/bill/payment`,
                  this.fineObject
              )
              .then((result) => {
                this.setLoader(false);
                if (this.checkApiRes(result, this.fineObject, "sale/bill/payment", "post")) {
                  // ok
                  this.success(
                      result.data.dialog.title,
                      result.data.dialog.message
                  );
                  console.log("full bill payment: ", result);
                }
                this.contact = null;
                this.contactvisible = false;
                this.toggleModal();
              });
        }
      }
    },
    success(title, message) {
      this.$success({
        title: title,
        // JSX support
        content: (
            <div>
              <p>{message}</p>
            </div>
        ),
      });
    },
    payfine(price, id, prices) {
      console.log(price, id);
      this.fineObject = {};
      this.fineObject.price = price;
      this.fineObject.id = id;
      this.fineObject.amount = null;
      this.apiCost = prices;
      this.toggleContactModal();
      // open modal to take contact
      // on submit put amount null because it's like pay all
      // POST sale/bill/payment id-contact-amount-(BaseSale_price)
    },
    getAxios() {
      return axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
      });
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          values.id = this.parkmeterObject.data.id;
          console.log("first parkmeter values", values);
          this.setLoader(true);
          this.getAxios()
              .post(
                  `${this.$session.get("baseURI")}sale/car/ticket/retrieval`,
                  values
              )
              .then((result) => {
                this.setLoader(false);
                if (this.checkApiRes(result, values, "sale/car/ticket/retrieval", "post")) {
                  // ok
                  console.log("parkmeter get", result);
                  this.parkmeterModalObject = {
                    title: `${values.plateCode}${values.plateNumber}`,
                    data: result.data.data,
                  };
                  this.toggleModal();
                }
              });
        }
      });
    },
  },
  created() {
    this.checkLocal();
    console.log(
        "parkmeter: ",
        this.$route.params.object || this.$session.get("backup")
    );
    if (this.$route.params.old) {
      let values = this.$route.params.old;
      this.handleOldFine(values);
    }
    if (!this.$route.params.object) {
      //undefined take from cache
      this.parkmeterObject = this.$session.get("backup");
    } else {
      // defined
      this.parkmeterObject = this.$route.params.object;
      this.$session.set("backup", this.$route.params.object);
    }
  },
};
</script>

<style lang="scss" scoped>
.fine {
  padding: 10px;
  background: lightgray;
  color: white;
  border-radius: 20px;
}

.align-right {
  text-align: right;
}

.whish-button {
  background-color: unset;
  border-radius: 45px;
  color: #e62946;
  border: 1px solid #e62946;
}

.info-header {
  background: gray;
  border-radius: 30px;
  padding: 15px;

  p {
    color: white;
    margin: 0px;
  }
}

.underline {
  text-decoration: underline;
}

.banner {
  width: 100%;
  position: absolute;
  z-index: 999;
  padding: 20px;
  background: #fffcdf;
  left: 0;
}

.banner-protection {
  position: absolute;
  width: 100%;
  height: 100%;
  background: lightgray;
  left: 0;
  z-index: 998;
  opacity: 0.5;
}

.card_custom {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
  padding: 2%;
  height: 70px;
  margin-bottom: 10px;
}

.card_custom:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.pointer {
  cursor: pointer;
}
</style>
